import React from "react";
import styled, { keyframes } from "styled-components";
import { constants } from "constants";

const visibilityAnimation = keyframes`
  from {
    width: 100vw;
    height: 100vh;
    opacity: 1;
  }
  99% {
    width: 100vw;
    height: 100vh;
    opacity: 0;
  }
  to {
    width: 0px;
    height: 0px;
    opacity: 0;
  }
`;

const moveAnimation = keyframes`
  from {
    transform: translateY(7px);
  }
  50% {
    transform: translateY(-7px);
  }
  to {
    transform: translateY(7px);
  }
`;

const StyledLoader = styled.div`
  position: fixed;
  z-index: 4;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  width: 100vw;
  height: 100vh;
  background-color: ${constants.colors.grey};
  display: flex;
  justify-content: center;
  align-items: center;
  transition: width 1s, height 1s, opacity 1s;
  will-change: width, height, opacity;

  &.hidden {
    animation: ${visibilityAnimation} 1s forwards;
  }
`;

const StyledDots = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const StyledDot = styled.div`
  width: 5px;
  height: 5px;
  border-radius: 5px;
  background-color: ${({ $dotIndex }) =>
    $dotIndex !== 0 ? constants.colors.bit : constants.colors.essence};
  margin-left: 2px;
  margin-right: 2px;
  transform: translateY(7px);
  transition: transform 1s;
  will-change: transform;
  animation: ${moveAnimation} 1s ease infinite;
  animation-delay: ${({ $dotIndex }) => $dotIndex * 166.66}ms;
`;

export const Loader = ({ visible }) => {
  return (
    <StyledLoader className={!visible ? "hidden" : undefined}>
      <StyledDots>
        {new Array(constants.loadingDotsCount)
          .fill(undefined)
          .map((_, dotIndex) => (
            <StyledDot key={dotIndex} $dotIndex={dotIndex} />
          ))}
      </StyledDots>
    </StyledLoader>
  );
};
