import backgroundRunnersParameters from "components/backgroundRunners/backgroundRunnersParameters.json";

export const isCursorCollisioned = (runner, { cursorX, cursorYWithOffset }) => {
  const runnerCenterX = runner.x + backgroundRunnersParameters.runnerRadius;
  const runnerCenterY = runner.y + backgroundRunnersParameters.runnerRadius;

  return (
    Math.abs(runnerCenterX - cursorX) ** 2 +
      Math.abs(runnerCenterY - cursorYWithOffset) ** 2 <=
    backgroundRunnersParameters.runnerRadius ** 2
  );
};
