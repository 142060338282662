import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import backgroundRunnersParameters from "components/backgroundRunners/backgroundRunnersParameters.json";
import { draw } from "components/backgroundRunners/canvas";
import { useRunnersInitializer } from "components/backgroundRunners/hooks/useRunnersInitializer";
import { useUpdater } from "components/backgroundRunners/hooks/useUpdater";
import { useRunnersAnimator } from "components/backgroundRunners/hooks/useRunnersAnimator";
import { MarginHitContext } from "contexts/marginHitContext";
import { constants } from "constants";

const StyledBackgroundRunners = styled.div`
  position: absolute;
  left: 0px;
  right: 0px;
  height: 100%;
`;

const StyledWall = styled.div`
  position: absolute;
  z-index: 1;
`;

const StyledBackground = styled.canvas`
  position: absolute;
  left: 0px;
  right: 0px;
  z-index: 0;
`;

export const BackgroundRunners = ({
  backgroundYOffset,
  size: { width, height },
}) => {
  const backgroundRef = useRef(null);
  const background2dContextRef = useRef(null);
  const [isBackgroundInitialized, setIsBackgroundInitialized] = useState(false);

  const {
    isTopMarginHit,
    isBottomMarginHit,
    isLeftMarginHit,
    isRightMarginHit,
  } = useContext(MarginHitContext);

  useEffect(() => {
    if (backgroundRef.current !== null) {
      background2dContextRef.current = backgroundRef.current.getContext("2d");
      setIsBackgroundInitialized(true);
    }
  }, [backgroundRef.current, width, height]);

  const maximumX = useMemo(
    () =>
      (backgroundRef.current?.clientWidth ?? 0) -
      backgroundRunnersParameters.runnerRadius * 2,
    [backgroundRef.current?.clientWidth]
  );

  const maximumY = useMemo(
    () =>
      (backgroundRef.current?.clientHeight ?? 0) -
      backgroundRunnersParameters.runnerRadius * 2,
    [backgroundRef.current?.clientHeight]
  );

  const { runners } = useRunnersInitializer(
    isBackgroundInitialized ? maximumX : undefined,
    isBackgroundInitialized ? maximumY : undefined
  );

  const { updater } = useUpdater();

  useRunnersAnimator(runners, maximumX, maximumY, backgroundYOffset, updater);

  useEffect(() => {
    if (background2dContextRef.current !== null && runners.length !== 0) {
      draw(background2dContextRef.current, runners);
    }
  }, [updater, background2dContextRef.current, runners.length]);

  return (
    <StyledBackgroundRunners>
      {width !== 0 && height !== 0 && (
        <StyledBackground
          ref={backgroundRef}
          width={width}
          height={height}
          style={{
            width,
            height,
          }}
        />
      )}
      <StyledWall
        style={{
          width,
          height: 1,
          top: 0,
          boxShadow: isTopMarginHit
            ? `0 0 5px ${constants.colors.almostWhite}66`
            : "none",
        }}
      />
      <StyledWall
        style={{
          width,
          height: 1,
          bottom: 0,
          boxShadow: isBottomMarginHit
            ? `0 0 5px ${constants.colors.almostWhite}66`
            : "none",
        }}
      />
      <StyledWall
        style={{
          width: 1,
          height,
          left: 0,
          boxShadow: isLeftMarginHit
            ? `0 0 5px ${constants.colors.almostWhite}66`
            : "none",
        }}
      />
      <StyledWall
        style={{
          width: 1,
          height,
          right: 0,
          boxShadow: isRightMarginHit
            ? `0 0 5px ${constants.colors.almostWhite}66`
            : "none",
        }}
      />
    </StyledBackgroundRunners>
  );
};
