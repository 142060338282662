import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { constants } from "constants";
import { Map } from "components/location/map";

const message = "We are currently here";

const StyledLocation = styled.div`
  position: absolute;
  bottom: 0px;
  left: -170px;
  height: 360px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;

  @media (max-width: 1300px) {
    left: -70px;
    height: 180px;
  }

  @media (max-width: 900px) {
    position: static;
    bottom: auto;
    left: auto;
    margin-top: 100px;
    align-items: center;
  }
`;

const StyledInnerLocation = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const StyledMessage = styled.h6`
  font-family: Roboto-Regular;
  font-size: 10px;
  color: ${constants.colors.orange};
  text-transform: uppercase;
  margin-top: 0px;
  margin-bottom: 10px;
`;

const StyledLetter = styled.span`
  opacity: ${({ opacity }) => opacity};
  transition: opacity 0.1s;
  will-change: opacity;
`;

const StyledContainer = styled.div`
  flex: 1;
  width: 180px;

  @media (max-width: 900px) {
    width: 360px;
  }

  @media (max-width: 600px) {
    width: 180px;
  }

  @media (max-width: 400px) {
    width: 120px;
  }
`;

const StyledMap = styled.div`
  height: 100%;
`;

export const Location = () => {
  const [shownLettersCount, setShownLettersCount] = useState(0);
  const [flickeringCounter, setFlickeringCounter] = useState(0);

  useEffect(() => {
    if (shownLettersCount === message.length) {
      setTimeout(() => {
        setShownLettersCount(
          (previousShownLettersCount) => previousShownLettersCount + 1
        );
      }, 700);
    } else if (shownLettersCount < message.length) {
      setTimeout(() => {
        setShownLettersCount(
          (previousShownLettersCount) => previousShownLettersCount + 1
        );
      }, 100);
    } else if (flickeringCounter <= 6) {
      setTimeout(() => {
        setFlickeringCounter(
          (previousFlickeringCounter) => previousFlickeringCounter + 1
        );
      }, 300);
    } else {
      setFlickeringCounter(0);
      setShownLettersCount(0);
    }
  }, [shownLettersCount, flickeringCounter]);

  return (
    <StyledLocation>
      <StyledInnerLocation>
        <StyledMessage>
          {message.split("").map((letter, letterIndex) => (
            <StyledLetter
              key={letterIndex}
              opacity={
                shownLettersCount <= message.length
                  ? letterIndex < shownLettersCount
                    ? 1
                    : 0
                  : flickeringCounter % 2 === 0
                  ? 0
                  : 1
              }
            >
              {letter}
            </StyledLetter>
          ))}
        </StyledMessage>
        <StyledContainer>
          <StyledMap>
            <Map />
          </StyledMap>
        </StyledContainer>
      </StyledInnerLocation>
    </StyledLocation>
  );
};
