import { useEffect, useMemo, useState } from "react";
import { constants } from "constants";
import { useLimitedWindowVerticalScroll } from "./useLimitedWindowVerticalScroll";

export const useHeader = () => {
  const [headerHeight, setHeaderHeight] = useState(constants.headerHeight);
  const { limitedVerticalScrollDirection } = useLimitedWindowVerticalScroll();

  useEffect(() => {
    if (limitedVerticalScrollDirection === "up") {
      setHeaderHeight(constants.headerHeight);
    } else {
      setHeaderHeight(constants.headerHeight * constants.headerHeightScaling);
    }
  }, [limitedVerticalScrollDirection]);

  const header = useMemo(
    () => ({
      headerHeight,
    }),
    [headerHeight]
  );

  return header;
};
