import React from "react";
import styled from "styled-components";
import { constants } from "constants";

const StyledAboutUs = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;
  margin-left: 100px;
  margin-right: 100px;

  &:hover {
    & > h2 {
      transform: scaleX(1.2);
    }
  }

  @media (max-width: 1000px) {
    margin-left: 0px;
    margin-right: 0px;
  }

  @media (max-width: 400px) {
    &:hover {
      & > h2 {
        transform: scaleX(1.1);
      }
    }
  }
`;

const StyledHeader = styled.h2`
  font-family: Roboto-Black;
  font-size: 40px;
  color: ${constants.colors.white};
  text-align: center;
  text-transform: uppercase;
  transition: transform 1s;
  will-change: transform;

  @media (max-width: 800px) {
    font-size: 30px;
  }

  @media (max-width: 500px) {
    font-size: 20px;
  }

  @media (max-width: 400px) {
    font-size: 15px;
  }

  @media (max-width: 350px) {
    font-size: 12px;
  }
`;

const StyledMessage = styled.span`
  font-family: Roboto-Light;
  font-size: 23px;
  color: ${constants.colors.almostWhite};
  text-align: center;

  @media (max-width: 800px) {
    font-size: 20px;
  }

  @media (max-width: 600px) {
    font-size: 15px;
  }

  @media (max-width: 400px) {
    font-size: 13px;
  }
`;

const StyledEssencebit = styled.span`
  font-family: Roboto-Black;
`;

export const AboutUs = () => {
  return (
    <StyledAboutUs id="about-us">
      <StyledHeader>About us</StyledHeader>
      <StyledMessage>
        <StyledEssencebit>Essencebit</StyledEssencebit> is a software
        development company based in Moldova. We have solid expertise in web and
        mobile development, proven by years of work in these fields. Our mission
        - to make businesses flourish. Our credo - craftsmanship and attitude
        over everything. Your successful business is our bread and butter. We'll
        take care of your business like no other. A stunning experience is what
        we offer, and it'll always be like this.
      </StyledMessage>
    </StyledAboutUs>
  );
};
