import { useCallback, useEffect, useMemo, useRef, useState } from "react";

export const useLimitedWindowVerticalScroll = () => {
  const limitedVerticalScrollRef = useRef(0);

  const [limitedVerticalScrollDirection, setLimitedVerticalScrollDirection] =
    useState("up");

  const changeLimitedVerticalScroll = useCallback(() => {
    const isScrollBeyondBottom =
      document.body.getBoundingClientRect().bottom <= window.innerHeight + 100;

    const isScrollBeyondTop = document.body.getBoundingClientRect().top >= -100;

    if (!isScrollBeyondBottom && !isScrollBeyondTop) {
      if (limitedVerticalScrollRef.current < window.scrollY) {
        setLimitedVerticalScrollDirection("down");
      } else {
        setLimitedVerticalScrollDirection("up");
      }

      limitedVerticalScrollRef.current = window.scrollY;
    }
  }, []);

  useEffect(() => {
    const scrollEvent = window.addEventListener(
      "scroll",
      changeLimitedVerticalScroll
    );

    return () => {
      scrollEvent.remove();
    };
  }, []);

  const limitedWindowVerticalScroll = useMemo(
    () => ({
      limitedVerticalScrollDirection,
    }),
    [limitedVerticalScrollDirection]
  );

  return limitedWindowVerticalScroll;
};
