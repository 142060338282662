import React from "react";
import { Link } from "react-scroll";

export const HashLink = ({ id, text, offset, className }) => {
  return (
    <Link
      className={className}
      to={id}
      smooth
      offset={offset}
      duration={0}
      delay={0}
    >
      {text}
    </Link>
  );
};
