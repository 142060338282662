import React from "react";
import styled, { keyframes } from "styled-components";
import { constants } from "constants";
import githubImage from "images/oss/github.webp";
import siImage from "images/oss/si.webp";
import ssgImage from "images/oss/ssg.webp";
import { Image } from "components/image";

const borderBottomLengthAnimation = keyframes`
  from {
    bottom: 0%;
    left: 50%;
    right: 50%;
    opacity: 0;
  }
  to {
    bottom: -3px;
    left: 0px;
    right: 0px;
    opacity: 1;
  }
`;

const StyledOss = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;
  margin-left: 100px;
  margin-right: 100px;

  &:hover {
    & > h2 {
      transform: scaleX(1.2);
    }
  }

  @media (max-width: 1000px) {
    margin-left: 0px;
    margin-right: 0px;
  }

  @media (max-width: 400px) {
    &:hover {
      & > h2 {
        transform: scaleX(1.1);
      }
    }
  }
`;

const StyledHeader = styled.h2`
  font-family: Roboto-Black;
  font-size: 40px;
  color: ${constants.colors.white};
  text-align: center;
  text-transform: uppercase;
  transition: transform 1s;
  will-change: transform;

  @media (max-width: 800px) {
    font-size: 30px;
  }

  @media (max-width: 500px) {
    font-size: 20px;
  }

  @media (max-width: 400px) {
    font-size: 15px;
  }

  @media (max-width: 350px) {
    font-size: 12px;
  }
`;

const StyledMessageSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const StyledMessage = styled.span`
  font-family: Roboto-Light;
  font-size: 23px;
  color: ${constants.colors.almostWhite};
  text-align: left;
  margin-right: 10px;

  @media (max-width: 800px) {
    font-size: 20px;
  }

  @media (max-width: 600px) {
    font-size: 15px;
  }

  @media (max-width: 400px) {
    font-size: 13px;
  }
`;

const StyledRepos = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 20px;
  justify-items: flex-start;
  align-items: center;
  margin-top: 20px;
  margin-left: 200px;
  margin-right: 200px;

  @media (max-width: 1500px) {
    margin-left: 100px;
    margin-right: 100px;
  }

  @media (max-width: 1000px) {
    margin-left: 0px;
    margin-right: 0px;
  }
`;

const StyledRepoTitle = styled.span`
  font-family: Roboto-Black;
  font-size: 23px;
  color: ${constants.colors.almostWhite};
  text-align: left;

  @media (max-width: 800px) {
    font-size: 20px;
  }

  @media (max-width: 600px) {
    font-size: 15px;
  }

  @media (max-width: 400px) {
    font-size: 13px;
  }
`;

const StyledRepoTitleLink = styled.a`
  color: ${constants.colors.almostWhite};
  text-decoration: none;
  cursor: pointer;
  transition: opacity 1s;
  will-change: opacity;

  &:hover {
    opacity: 0.5;
  }
`;

const StyledRepoDescription = styled.span`
  font-family: Roboto-Light;
  font-size: 18px;
  color: ${constants.colors.almostWhite};
  text-align: center;

  @media (max-width: 800px) {
    font-size: 15px;
  }

  @media (max-width: 600px) {
    font-size: 12px;
  }

  @media (max-width: 400px) {
    font-size: 10px;
  }
`;

const StyledGitHubLink = styled.a`
  position: relative;
  color: ${constants.colors.almostWhite};
  text-decoration: none;
  cursor: pointer;
  transition: opacity 1s;
  will-change: opacity;

  &:after {
    content: "";
    position: absolute;
    top: 100%;
    bottom: 0%;
    left: 50%;
    right: 50%;
    opacity: 0;
    background-color: transparent;
  }

  &:hover {
    opacity: 0.5;

    &:after {
      content: "";
      position: absolute;
      top: 100%;
      bottom: -3px;
      left: 0px;
      right: 0px;
      opacity: 1;
      background-color: ${constants.colors.white};
      animation: ${borderBottomLengthAnimation} 1s;
    }
  }
`;

const StyledGitHub = styled.a`
  cursor: pointer;
  transition: opacity 1s;
  will-change: opacity;

  &:hover {
    opacity: 0.5;
  }
`;

const StyledGitHubImage = styled(Image)`
  width: 40px;
  height: 40px;

  @media (max-width: 800px) {
    width: 30px;
    height: 30px;
  }

  @media (max-width: 400px) {
    width: 20px;
    height: 20px;
  }
`;

const StyledRepo = styled.a`
  cursor: pointer;
  transition: opacity 1s;
  will-change: opacity;

  &:hover {
    opacity: 0.5;
  }
`;

const StyledRepoImage = styled(Image)`
  width: 80px;
  height: 80px;

  @media (max-width: 800px) {
    width: 60px;
    height: 60px;
  }

  @media (max-width: 400px) {
    width: 40px;
    height: 40px;
  }
`;

export const Oss = () => {
  return (
    <StyledOss id="oss">
      <StyledHeader>We contribute to open source</StyledHeader>
      <StyledMessageSection>
        <StyledMessage>
          Check out our{" "}
          <StyledGitHubLink
            href="https://github.com/essencebit"
            target="_blank"
          >
            GitHub
          </StyledGitHubLink>
          <StyledGitHub href="https://github.com/essencebit" target="_blank">
            <StyledGitHubImage src={githubImage} alt="GitHub" />
          </StyledGitHub>
          . We placed several Nugets there.
        </StyledMessage>
      </StyledMessageSection>
      <StyledRepos>
        <StyledRepo
          href="https://www.nuget.org/packages/SignalRSwaggerGen"
          target="_blank"
        >
          <StyledRepoImage src={ssgImage} alt="SignalRSwaggerGen" />
        </StyledRepo>
        <StyledRepoTitle>
          <StyledRepoTitleLink
            href="https://www.nuget.org/packages/SignalRSwaggerGen"
            target="_blank"
          >
            SignalRSwaggerGen
          </StyledRepoTitleLink>
          <br />
          <StyledRepoDescription>
            This package can be used to generate Swagger documentation for
            SignalR hubs.
          </StyledRepoDescription>
        </StyledRepoTitle>
        <StyledRepo
          href="https://www.nuget.org/packages/SerializationInterceptor"
          target="_blank"
        >
          <StyledRepoImage src={siImage} alt="SerializationInterceptor" />
        </StyledRepo>
        <StyledRepoTitle>
          <StyledRepoTitleLink
            href="https://www.nuget.org/packages/SerializationInterceptor"
            target="_blank"
          >
            SerializationInterceptor
          </StyledRepoTitleLink>
          <br />
          <StyledRepoDescription>
            This package can be used to intercept attribute parameters' values
            during serialization/deserialization process.
          </StyledRepoDescription>
        </StyledRepoTitle>
      </StyledRepos>
    </StyledOss>
  );
};
