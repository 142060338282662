import React from "react";
import styled from "styled-components";
import { VisibilityAnimator } from "components/visibilityAnimator";
import { Expertise } from "components/expertise";
import { Location } from "components/location";
import { Contacts } from "components/contacts";
import { AboutUs } from "components/aboutUs";
import { OurValues } from "components/ourValues";
import { OurServices } from "components/ourServices";
import { WhyChooseUs } from "components/whyChooseUs";
import { Oss } from "components/oss";

const StyledBody = styled.div`
  position: relative;
  margin-bottom: 50px;
`;

export const Body = () => {
  return (
    <StyledBody>
      <VisibilityAnimator animationType="opacity">
        <AboutUs />
      </VisibilityAnimator>
      <VisibilityAnimator animationType="opacity">
        <OurValues />
      </VisibilityAnimator>
      <VisibilityAnimator animationType="opacity">
        <OurServices />
      </VisibilityAnimator>
      <VisibilityAnimator animationType="opacity" zIndex={1}>
        <Expertise />
      </VisibilityAnimator>
      <VisibilityAnimator animationType="opacity">
        <Oss />
      </VisibilityAnimator>
      <VisibilityAnimator animationType="opacity">
        <WhyChooseUs />
      </VisibilityAnimator>
      <VisibilityAnimator animationType="opacity">
        <Contacts />
      </VisibilityAnimator>
      <VisibilityAnimator animationType="opacity">
        <Location />
      </VisibilityAnimator>
    </StyledBody>
  );
};
