import React from "react";
import { useHeader } from "hooks/useHeader";
import { HeaderContext } from "contexts/headerContext";

export const HeaderWrapper = ({ children }) => {
  const header = useHeader();

  return (
    <HeaderContext.Provider value={header}>{children}</HeaderContext.Provider>
  );
};
