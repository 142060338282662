import React, { useCallback, useContext, useRef, useState } from "react";
import styled, { keyframes } from "styled-components";
import { constants } from "constants";
import { useResizeObserver } from "hooks/useResizeObserver";
import { TooltipPortalContext } from "contexts/tooltipPortalContext";
import { createPortal } from "react-dom";

const appearanceAnimation = keyframes`
  from {
    opacity: 0;
    font-size: 0px;
    padding: 0px;
  }
  1% {
    opacity: 0;
    font-size: 13px;
    padding: 5px 10px;
  }
  2% {
    opacity: 0;
    font-size: 13px;
    padding: 5px 10px;
  }
  to {
    opacity: 1;
    font-size: 13px;
    padding: 5px 10px;
  }
`;

const disappearanceAnimation = keyframes`
  from {
    opacity: 1;
    font-size: 13px;
    padding: 5px 10px;
  }
  98% {
    opacity: 0;
    font-size: 13px;
    padding: 5px 10px;
  }
  99% {
    opacity: 0;
    font-size: 13px;
    padding: 5px 10px;
  }
  to {
    opacity: 0;
    font-size: 0px;
    padding: 0px;
  }
`;

const StyledTip = styled.div`
  position: relative;
`;

const StyledTooltip = styled.div`
  position: fixed;
  z-index: 3;
  background-color: ${constants.colors.darkGrey}ee;
  font-family: Roboto-Black;
  font-size: 0px;
  color: ${constants.colors.white};
  text-align: center;
  padding: 0px;
  border-radius: 5px;
  opacity: 0;
  transition: font-size 1s, padding 1s, opacity 1s;
  will-change: font-size, padding, opacity;

  &.visible {
    animation: ${appearanceAnimation} 1s forwards;
  }

  &.hidden {
    animation: ${disappearanceAnimation} 1s forwards;
  }
`;

export const Tip = ({ target, tip, disabled, portalized }) => {
  const { tooltipPortalRef } = useContext(TooltipPortalContext);
  const tooltipRef = useRef(null);
  const [positionX, setPositionX] = useState(null);
  const [positionY, setPositionY] = useState(null);
  const [isTooltipVisible, setIsTooltipVisible] = useState(null);
  const timeoutIdRef = useRef(null);
  const { observedHeight } = useResizeObserver(tooltipRef);

  const showTooltip = useCallback((event) => {
    setPositionX(event.clientX);
    setPositionY(event.clientY);

    timeoutIdRef.current = setTimeout(() => {
      setIsTooltipVisible(true);
    }, 500);
  }, []);

  const updateTooltipPosition = useCallback((event) => {
    setPositionX(event.clientX);
    setPositionY(event.clientY);
  }, []);

  const hideTooltip = useCallback(() => {
    clearTimeout(timeoutIdRef.current);
    setIsTooltipVisible(false);
    setPositionX(null);
    setPositionY(null);
  }, []);

  return (
    <StyledTip
      onMouseEnter={!disabled ? showTooltip : undefined}
      onMouseMove={!disabled ? updateTooltipPosition : undefined}
      onMouseLeave={!disabled ? hideTooltip : undefined}
    >
      {target}
      {!disabled && positionX !== null && positionY !== null && (
        <>
          {portalized ? (
            createPortal(
              <StyledTooltip
                ref={tooltipRef}
                className={
                  isTooltipVisible === null
                    ? undefined
                    : isTooltipVisible
                    ? "visible"
                    : undefined
                }
                style={{
                  left: positionX + 3,
                  top: positionY - observedHeight,
                }}
                onClick={hideTooltip}
              >
                {tip}
              </StyledTooltip>,
              tooltipPortalRef.current
            )
          ) : (
            <StyledTooltip
              ref={tooltipRef}
              className={
                isTooltipVisible === null
                  ? undefined
                  : isTooltipVisible
                  ? "visible"
                  : undefined
              }
              style={{
                left: positionX + 3,
                top: positionY - observedHeight,
              }}
              onClick={hideTooltip}
            >
              {tip}
            </StyledTooltip>
          )}
        </>
      )}
    </StyledTip>
  );
};
