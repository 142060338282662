// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./fonts/Roboto/Roboto-Black.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./fonts/Roboto/Roboto-Light.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./fonts/Roboto/Roboto-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("./fonts/AustraliaCustom/AustraliaCustom-Italic.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face {\n  font-family: \"Roboto-Black\";\n  src: local(\"Roboto-Black\"),\n    url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"truetype\");\n}\n\n@font-face {\n  font-family: \"Roboto-Light\";\n  src: local(\"Roboto-Light\"),\n    url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"truetype\");\n}\n\n@font-face {\n  font-family: \"Roboto-Regular\";\n  src: local(\"Roboto-Regular\"),\n    url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"truetype\");\n}\n\n@font-face {\n  font-family: \"AustraliaCustom-Italic\";\n  src: local(\"AustraliaCustom-Italic\"),\n    url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format(\"truetype\");\n}\n\nhtml {\n  scroll-behavior: smooth;\n}\n\nbody {\n  margin: 0px;\n  overflow-x: hidden;\n  overscroll-behavior: none;\n}\n", "",{"version":3,"sources":["webpack://./src/app.css"],"names":[],"mappings":"AAAA;EACE,2BAA2B;EAC3B;8DACyD;AAC3D;;AAEA;EACE,2BAA2B;EAC3B;8DACyD;AAC3D;;AAEA;EACE,6BAA6B;EAC7B;8DAC2D;AAC7D;;AAEA;EACE,qCAAqC;EACrC;8DAC4E;AAC9E;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,yBAAyB;AAC3B","sourcesContent":["@font-face {\n  font-family: \"Roboto-Black\";\n  src: local(\"Roboto-Black\"),\n    url(./fonts/Roboto/Roboto-Black.ttf) format(\"truetype\");\n}\n\n@font-face {\n  font-family: \"Roboto-Light\";\n  src: local(\"Roboto-Light\"),\n    url(./fonts/Roboto/Roboto-Light.ttf) format(\"truetype\");\n}\n\n@font-face {\n  font-family: \"Roboto-Regular\";\n  src: local(\"Roboto-Regular\"),\n    url(./fonts/Roboto/Roboto-Regular.ttf) format(\"truetype\");\n}\n\n@font-face {\n  font-family: \"AustraliaCustom-Italic\";\n  src: local(\"AustraliaCustom-Italic\"),\n    url(./fonts/AustraliaCustom/AustraliaCustom-Italic.ttf) format(\"truetype\");\n}\n\nhtml {\n  scroll-behavior: smooth;\n}\n\nbody {\n  margin: 0px;\n  overflow-x: hidden;\n  overscroll-behavior: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
