import backgroundRunnersParameters from "components/backgroundRunners/backgroundRunnersParameters.json";

export const draw = (context, runners) => {
  context.clearRect(0, 0, context.canvas.width, context.canvas.height);

  for (let runnerIndex = 0; runnerIndex < runners.length; runnerIndex++) {
    const { color, x, y } = runners[runnerIndex];
    const runnerCenterX = x + backgroundRunnersParameters.runnerRadius;
    const runnerCenterY = y + backgroundRunnersParameters.runnerRadius;

    context.beginPath();
    context.fillStyle = color;

    context.arc(
      runnerCenterX,
      runnerCenterY,
      backgroundRunnersParameters.runnerRadius,
      0,
      2 * Math.PI
    );

    context.fill();
    context.lineWidth = 0.5;
    context.strokeStyle = color;
    context.stroke();
  }
};
