import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import "app.css";
import { Header } from "components/header";
import { Motto } from "components/motto";
import { VisibilityAnimator } from "components/visibilityAnimator";
import { Footer } from "components/footer";
import { constants } from "constants";
import { BackgroundRunners } from "components/backgroundRunners";
import { useResizeObserver } from "hooks/useResizeObserver";
import { TooltipPortalContext } from "contexts/tooltipPortalContext";
import { Body } from "components/body";
import { TopScroller } from "components/topScroller";

const StyledApp = styled.div`
  background-color: ${constants.colors.grey};
  padding-top: ${constants.headerHeight}px;
`;

const StyledBody = styled.div`
  position: relative;
  z-index: 1;
  padding-left: 200px;
  padding-right: 200px;
  overflow: hidden;

  @media (max-width: 1300px) {
    padding-left: 100px;
    padding-right: 100px;
  }

  @media (max-width: 600px) {
    padding-left: 30px;
    padding-right: 30px;
  }

  @media (max-width: 400px) {
    padding-left: 10px;
    padding-right: 10px;
  }
`;

const StyledTooltipPortal = styled.div`
  z-index: 3;
`;

export const LoadedApp = () => {
  const { tooltipPortalRef } = useContext(TooltipPortalContext);
  const bodyRef = useRef(null);
  const { observedWidth, observedHeight } = useResizeObserver(bodyRef);

  const [areBackgroundRunnersVisible, setAreBackgroundRunnersVisible] =
    useState(false);

  useEffect(() => {
    setTimeout(() => {
      setAreBackgroundRunnersVisible(true);
    }, 2000);
  }, []);

  const backgroundRunnersSize = useMemo(
    () => ({
      width: observedWidth,
      height: observedHeight,
    }),
    [observedWidth, observedHeight]
  );

  return (
    <StyledApp>
      <Header />
      <StyledBody ref={bodyRef}>
        {areBackgroundRunnersVisible && (
          <VisibilityAnimator animationType="opacity" nonRelative>
            <BackgroundRunners
              backgroundYOffset={constants.headerHeight}
              size={backgroundRunnersSize}
            />
          </VisibilityAnimator>
        )}
        <VisibilityAnimator animationType="opacity" nonRelative>
          <Motto />
        </VisibilityAnimator>
        <Body />
      </StyledBody>
      <VisibilityAnimator animationType="opacity" zIndex={1}>
        <TopScroller />
      </VisibilityAnimator>
      <VisibilityAnimator animationType="opacity" zIndex={0}>
        <Footer />
      </VisibilityAnimator>
      <StyledTooltipPortal ref={tooltipPortalRef} />
    </StyledApp>
  );
};
