import React, { useEffect, useState } from "react";
import "app.css";
import { constants } from "constants";
import { useFontsObserver } from "hooks/useFontsObserver";
import { Loader } from "components/loader";
import { LoadedApp } from "loadedApp";
import { HeaderWrapper } from "wrappers/headerWrapper";
import { MarginHitWrapper } from "wrappers/marginHitWrapper";
import { TooltipPortalWrapper } from "wrappers/tooltipPortalWrapper";

export const App = () => {
  const { areAllFontsObserved } = useFontsObserver();
  const [isLoaderShown, setIsLoaderShown] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoaderShown(false);
    }, constants.minimumLoaderShowTime);
  }, []);

  const isLoaderVisible = !areAllFontsObserved || isLoaderShown;

  return (
    <>
      <Loader visible={isLoaderVisible} />
      {!isLoaderVisible && (
        <HeaderWrapper>
          <MarginHitWrapper>
            <TooltipPortalWrapper>
              <LoadedApp />
            </TooltipPortalWrapper>
          </MarginHitWrapper>
        </HeaderWrapper>
      )}
    </>
  );
};
