import React from "react";
import styled from "styled-components";
import { constants } from "constants";

const StyledWhyChooseUs = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;
  margin-left: 100px;
  margin-right: 100px;

  &:hover {
    & > h2 {
      transform: scaleX(1.2);
    }
  }

  @media (max-width: 1000px) {
    margin-left: 0px;
    margin-right: 0px;
  }

  @media (max-width: 400px) {
    &:hover {
      & > h2 {
        transform: scaleX(1.1);
      }
    }
  }
`;

const StyledHeader = styled.h2`
  font-family: Roboto-Black;
  font-size: 40px;
  color: ${constants.colors.white};
  text-align: center;
  text-transform: uppercase;
  transition: transform 1s;
  will-change: transform;

  @media (max-width: 800px) {
    font-size: 30px;
  }

  @media (max-width: 500px) {
    font-size: 20px;
  }

  @media (max-width: 400px) {
    font-size: 15px;
  }

  @media (max-width: 350px) {
    font-size: 12px;
  }
`;

const StyledMessage = styled.span`
  font-family: Roboto-Light;
  font-size: 23px;
  color: ${constants.colors.almostWhite};
  text-align: center;

  @media (max-width: 800px) {
    font-size: 20px;
  }

  @media (max-width: 600px) {
    font-size: 15px;
  }

  @media (max-width: 400px) {
    font-size: 13px;
  }
`;

export const WhyChooseUs = () => {
  return (
    <StyledWhyChooseUs id="why-choose-us">
      <StyledHeader>Why choose us</StyledHeader>
      <StyledMessage>
        It's simple. Because we can give you what you want - a high-quality
        product in a timely manner and for reasonable money. We come with a
        powerful work approach based on technical excellence, a friendly work
        environment, transparency in actions and the "solutions by any means"
        principle. Digital transformation of your business will be in good
        hands. We're very serious in what we do. You'll get the best value for
        money.
      </StyledMessage>
    </StyledWhyChooseUs>
  );
};
