import { useCallback, useEffect, useRef, useState } from "react";
import backgroundRunnersParameters from "components/backgroundRunners/backgroundRunnersParameters.json";

export const useUpdater = () => {
  const [updater, setUpdater] = useState(0);
  const previousUpdateTimeRef = useRef(new Date().getMinutes());
  const animationFrameRequestIdRef = useRef(null);

  const update = useCallback((time) => {
    if (
      time - previousUpdateTimeRef.current >=
      backgroundRunnersParameters.updateInterval
    ) {
      previousUpdateTimeRef.current = time;
      setUpdater((previousUpdater) => previousUpdater + 1);
    }

    animationFrameRequestIdRef.current = requestAnimationFrame(update);
  }, []);

  useEffect(() => {
    animationFrameRequestIdRef.current = requestAnimationFrame(update);

    return () => {
      cancelAnimationFrame(animationFrameRequestIdRef.current);
    };
  }, []);

  return {
    updater,
  };
};
