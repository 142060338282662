export const constants = {
  colors: {
    black: "black",
    bit: "#3989a6",
    bitTransparent: "rgba(57,137,166,0.3)", // #3989a6
    essence: "#96b94b",
    essenceTransparent: "rgba(150,185,75,0.3)", // #96b94b
    grey: "#202123",
    darkGrey: "#181818",
    lightGrey: "#27282b",
    orange: "#e89b30",
    white: "white",
    almostWhite: "#dadada",
  },
  googleMapsApiKey: "AIzaSyCG1T7cOJiYOCtQDo3rTAMMumNZef4Aje8",
  headerHeight: window.innerWidth <= 700 ? 120 : 150,
  headerHeightScaling: window.innerWidth <= 700 ? 0.8 : 0.7,
  fontsLoadTimeout: 10000,
  minimumLoaderShowTime: 1500,
  loadingDotsCount: 6,
};
