import React, { useCallback, useContext, useMemo, useState } from "react";
import styled, { keyframes } from "styled-components";
import { AiOutlineMenu } from "react-icons/ai";
import { TfiClose } from "react-icons/tfi";
import { constants } from "constants";
import { useLimitedWindowVerticalScroll } from "hooks/useLimitedWindowVerticalScroll";
import { useWindowVerticalScroll } from "hooks/useWindowVerticalScroll";
import logoImage from "images/logo.webp";
import { HashLink } from "components/hashLink";
import { VisibilityAnimator } from "components/visibilityAnimator";
import { HeaderContext } from "contexts/headerContext";
import { Image } from "components/image";

const borderBottomLengthAnimation = keyframes`
  from {
    bottom: 0%;
    left: 50%;
    right: 50%;
    opacity: 0;
  }
  to {
    bottom: -3px;
    left: 0px;
    right: 0px;
    opacity: 1;
  }
`;

const appearanceAnimation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const disappearanceAnimation = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

const fontSizeIncreaseAnimation = keyframes`
  from {
    font-size: 0px;
  }
  1% {
    font-size: 20px;
  }
  to {
    font-size: 20px;
  }
`;

const fontSizeDecreaseAnimation = keyframes`
  from {
    font-size: 20px;
  }
  99% {
    font-size: 20px;
  }
  to {
    font-size: 0px;
  }
`;

const smallFontSizeIncreaseAnimation = keyframes`
  from {
    font-size: 0px;
  }
  1% {
    font-size: 15px;
  }
  to {
    font-size: 15px;
  }
`;

const smallFontSizeDecreaseAnimation = keyframes`
  from {
    font-size: 15px;
  }
  99% {
    font-size: 15px;
  }
  to {
    font-size: 0px;
  }
`;

const StyledHeader = styled.div`
  position: fixed;
  top: 0px;
  width: 100%;
  height: ${constants.headerHeight}px;
  padding-left: 30px;
  padding-right: 30px;
  background-color: ${constants.colors.grey}ee;
  border-bottom: 1px solid
    ${({ $withBorder }) =>
      $withBorder ? `${constants.colors.lightGrey}` : "transparent"};
  box-shadow: ${({ $withBorder }) =>
    $withBorder ? `0 0 10px ${constants.colors.lightGrey}` : "none"};
  box-sizing: border-box;
  z-index: 2;
  transition: transform 1s, border-bottom 1s, box-shadow 1s;
  will-change: transform, border-bottom, box-shadow;

  &.scrolling-down {
    transform: scaleY(${constants.headerHeightScaling});
    transform-origin: center top;
  }

  &.scrolling-up {
    transform: scaleY(1);
    transform-origin: center top;
  }

  @media (max-width: 400px) {
    padding-left: 10px;
    padding-right: 10px;
  }
`;

const StyledInnerHeader = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
`;

const StyledLogoLinkContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  transition: transform 1s;
  will-change: transform;

  &.scrolling-down {
    transform: scaleX(${constants.headerHeightScaling});
    transform-origin: left center;
  }

  &.scrolling-up {
    transform: scaleX(1);
    transform-origin: left center;
  }
`;

const StyledLogoLink = styled.a`
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

const StyledLogo = styled(Image)`
  height: 100%;
`;

const StyledLinks = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  transition: margin-bottom 1s, transform 1s;
  will-change: margin-bottom, transform;

  &.scrolling-down {
    margin-bottom: 8px;
    transform: scaleY(calc(1 / ${constants.headerHeightScaling}));
    transform-origin: center bottom;
  }

  &.scrolling-up {
    margin-bottom: 20px;
    transform: scaleY(1);
    transform-origin: center bottom;
  }

  @media (max-width: 1000px) {
    display: none;
  }
`;

const StyledLink = styled(HashLink)`
  position: relative;
  font-family: Roboto-Regular;
  font-size: 25px;
  color: ${constants.colors.white};
  margin-left: 30px;
  cursor: pointer;
  text-transform: uppercase;
  transition: opacity 1s;
  will-change: opacity;

  &:after {
    content: "";
    position: absolute;
    top: 100%;
    bottom: 0%;
    left: 50%;
    right: 50%;
    opacity: 0;
    background-color: transparent;
  }

  &:hover {
    opacity: 0.5;

    &:after {
      content: "";
      position: absolute;
      top: 100%;
      bottom: -3px;
      left: 0px;
      right: 0px;
      opacity: 1;
      background-color: ${constants.colors.white};
      animation: ${borderBottomLengthAnimation} 1s;
    }
  }

  @media (max-width: 1200px) {
    font-size: 20px;
  }

  @media (max-width: 1100px) {
    font-size: 15px;
  }
`;

const StyledMenu = styled.div`
  display: none;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  transition: margin-bottom 1s;
  will-change: margin-bottom;

  &.scrolling-down {
    margin-bottom: 8px;
  }

  &.scrolling-up {
    margin-bottom: 20px;
  }

  @media (max-width: 1000px) {
    display: flex;
  }
`;

const StyledMenuTrigger = styled.div`
  font-size: ${({ $visible }) => ($visible ? 50 : 0)}px;
  line-height: 0px;
  color: ${constants.colors.white};
  cursor: pointer;
  transition: transform 1s, font-size 1s, opacity 1s;
  will-change: transform, font-size, opacity;

  &.scrolling-down {
    transform: scaleY(calc(1 / ${constants.headerHeightScaling}));
    transform-origin: right bottom;
  }

  &.scrolling-up {
    transform: scaleY(1);
    transform-origin: right bottom;
  }

  &:hover {
    opacity: 0.5;
  }

  @media (max-width: 700px) {
    font-size: ${({ $visible }) => ($visible ? 45 : 0)}px;
  }

  @media (max-width: 600px) {
    font-size: ${({ $visible }) => ($visible ? 40 : 0)}px;
  }

  @media (max-width: 400px) {
    font-size: ${({ $visible }) => ($visible ? 30 : 0)}px;
  }
`;

const StyledMenuDropdown = styled.div`
  position: absolute;
  top: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  background-color: ${constants.colors.grey}ee;
  height: 250px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  border-bottom-left-radius: 10px;
  border-left: 1px solid ${constants.colors.lightGrey};
  border-bottom: 1px solid ${constants.colors.lightGrey};
  box-shadow: 0 0 10px ${constants.colors.lightGrey};
  box-sizing: border-box;
  opacity: 0;
  transition: transform 1s, opacity 1s;
  will-change: transform, opacity;

  &.scrolling-down {
    transform: scaleY(calc(1 / ${constants.headerHeightScaling}));
    transform-origin: right top;
  }

  &.scrolling-up {
    transform: scaleY(1);
    transform-origin: right top;
  }

  &.visible {
    animation: ${appearanceAnimation} 1s forwards;
  }

  &.hidden {
    animation: ${disappearanceAnimation} 1s forwards;
  }

  & > a {
    font-size: 0px;
    transition: font-size 1s;
    will-change: font-size;
  }

  &.visible > a {
    animation: ${fontSizeIncreaseAnimation} 1.1s forwards;
  }

  &.hidden > a {
    animation: ${fontSizeDecreaseAnimation} 1.1s forwards;
  }

  @media (max-width: 1000px) {
    right: -30px;
    padding-right: 30px;
  }

  @media (max-width: 700px) {
    &.visible > a {
      animation: ${smallFontSizeIncreaseAnimation} 1.1s forwards;
    }

    &.hidden > a {
      animation: ${smallFontSizeDecreaseAnimation} 1.1s forwards;
    }
  }

  @media (max-width: 400px) {
    right: -10px;
    padding-right: 10px;
  }
`;

const StyledMenuLink = styled(HashLink)`
  position: relative;
  font-family: Roboto-Regular;
  color: ${constants.colors.white};
  cursor: pointer;
  text-transform: uppercase;
  transition: opacity 1s;
  will-change: opacity;

  &:after {
    content: "";
    position: absolute;
    top: 100%;
    bottom: 0%;
    left: 50%;
    right: 50%;
    opacity: 0;
    background-color: transparent;
  }

  &:hover {
    opacity: 0.5;

    &:after {
      content: "";
      position: absolute;
      top: 100%;
      bottom: -3px;
      left: 0px;
      right: 0px;
      opacity: 1;
      background-color: ${constants.colors.white};
      animation: ${borderBottomLengthAnimation} 1s;
    }
  }
`;

export const Header = () => {
  const { headerHeight } = useContext(HeaderContext);
  const { limitedVerticalScrollDirection } = useLimitedWindowVerticalScroll();
  const { verticalScroll } = useWindowVerticalScroll();

  const scrollingClassName = useMemo(
    () =>
      limitedVerticalScrollDirection === "up"
        ? "scrolling-up"
        : "scrolling-down",
    [limitedVerticalScrollDirection]
  );

  const [isMenuOpen, setIsMenuOpen] = useState(null);

  const openMenu = useCallback(() => {
    setIsMenuOpen(true);
  }, []);

  const closeMenu = useCallback(() => {
    setIsMenuOpen(false);
  }, []);

  return (
    <StyledHeader
      className={scrollingClassName}
      $withBorder={verticalScroll > 0}
    >
      <VisibilityAnimator animationType="opacity" showInstantly>
        <StyledInnerHeader>
          <StyledLogoLinkContainer className={scrollingClassName}>
            <StyledLogoLink href="/">
              <StyledLogo src={logoImage} alt="Essencebit" />
            </StyledLogoLink>
          </StyledLogoLinkContainer>
          <StyledLinks className={scrollingClassName}>
            <StyledLink
              id="about-us"
              text="About us"
              offset={-constants.headerHeight}
            />
            <StyledLink
              id="our-values"
              text="Values"
              offset={-constants.headerHeight}
            />
            <StyledLink
              id="our-services"
              text="Services"
              offset={-constants.headerHeight}
            />
            <StyledLink
              id="expertise"
              text="Expertise"
              offset={-constants.headerHeight}
            />
            <StyledLink id="oss" text="OSS" offset={-constants.headerHeight} />
            <StyledLink
              id="why-choose-us"
              text="Why us"
              offset={-constants.headerHeight}
            />
            <StyledLink
              id="contacts"
              text="Contacts"
              offset={-constants.headerHeight}
            />
          </StyledLinks>
          <StyledMenu className={scrollingClassName}>
            <StyledMenuTrigger
              className={scrollingClassName}
              $visible={!isMenuOpen}
              onClick={openMenu}
            >
              <AiOutlineMenu />
            </StyledMenuTrigger>
            <StyledMenuTrigger
              className={scrollingClassName}
              $visible={isMenuOpen}
              onClick={closeMenu}
            >
              <TfiClose />
            </StyledMenuTrigger>
            <StyledMenuDropdown
              className={[
                scrollingClassName,
                isMenuOpen === null
                  ? undefined
                  : isMenuOpen
                  ? "visible"
                  : "hidden",
              ].join(" ")}
            >
              <StyledMenuLink
                id="about-us"
                text="About us"
                offset={-constants.headerHeight}
              />
              <StyledMenuLink
                id="our-values"
                text="Values"
                offset={-constants.headerHeight}
              />
              <StyledMenuLink
                id="our-services"
                text="Services"
                offset={-constants.headerHeight}
              />
              <StyledMenuLink
                id="expertise"
                text="Expertise"
                offset={-constants.headerHeight}
              />
              <StyledMenuLink
                id="oss"
                text="OSS"
                offset={-constants.headerHeight}
              />
              <StyledMenuLink
                id="why-choose-us"
                text="Why us"
                offset={-constants.headerHeight}
              />
              <StyledMenuLink
                id="contacts"
                text="Contacts"
                offset={-constants.headerHeight}
              />
            </StyledMenuDropdown>
          </StyledMenu>
        </StyledInnerHeader>
      </VisibilityAnimator>
    </StyledHeader>
  );
};
