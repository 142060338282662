import { useCallback, useEffect, useState } from "react";

export const useCursor = () => {
  const [cursorX, setCursorX] = useState(0);
  const [cursorY, setCursorY] = useState(0);

  const updateCursor = useCallback(({ clientX, clientY }) => {
    setCursorX(clientX);
    setCursorY(clientY);
  }, []);

  useEffect(() => {
    window.addEventListener("mousemove", updateCursor);

    return () => {
      window.removeEventListener("mousemove", updateCursor);
    };
  }, []);

  return {
    cursorX,
    cursorY,
  };
};
