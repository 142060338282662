import { useEffect, useMemo, useState } from "react";

export const useVisibilityObserver = (elementRef) => {
  const [isVisible, setIsVisible] = useState(false);

  const observer = useMemo(
    () =>
      new IntersectionObserver(
        ([entry]) => {
          setIsVisible(
            (previousIsVisible) => previousIsVisible || entry.isIntersecting
          );
        },
        {
          threshold: 0,
        }
      ),
    []
  );

  useEffect(() => {
    if (elementRef.current) {
      observer.observe(elementRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [elementRef.current]);

  return {
    isVisible,
  };
};
