import { useEffect, useMemo, useState } from "react";

export const useResizeObserver = (elementRef) => {
  const [observedWidth, setObservedWidth] = useState(0);
  const [observedHeight, setObservedHeight] = useState(0);

  const observer = useMemo(
    () =>
      new ResizeObserver(([entry]) => {
        setObservedWidth(entry.target.clientWidth);
        setObservedHeight(entry.target.clientHeight);
      }),
    []
  );

  useEffect(() => {
    if (elementRef.current) {
      observer.observe(elementRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [elementRef.current]);

  return {
    observedWidth,
    observedHeight,
  };
};
