import React from "react";
import styled from "styled-components";
import { AiTwotonePhone } from "react-icons/ai";
import { FaGlobe } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { constants } from "constants";
import { ClipboardCopier } from "components/clipboardCopier";

const StyledFooter = styled.div`
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${constants.colors.black};

  @media (max-width: 700px) {
    height: 150px;
  }
`;

const StyledAddressAndContacts = styled.div`
  position: absolute;
  left: 30px;
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  @media (max-width: 800px) {
    position: static;
    left: auto;
    display: inline-flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  @media (max-width: 700px) {
    flex-direction: column;
    justify-content: flex-start;
  }
`;

const StyledAddress = styled.h6`
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 0px;
  font-family: Roboto-Regular;
  font-size: 13px;
  color: ${constants.colors.white};
  opacity: 0.5;
  cursor: pointer;

  @media (max-width: 400px) {
    font-size: 10px;
  }
`;

const StyledContactContainer = styled.div`
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 5px;

  @media (max-width: 800px) {
    margin-top: 0px;
    margin-left: 10px;
  }

  @media (max-width: 700px) {
    margin-top: 5px;
    margin-left: 0px;
  }
`;

const StyledContact = styled.h6`
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 0px;
  font-family: Roboto-Regular;
  font-size: 13px;
  color: ${constants.colors.white};
  opacity: 0.5;
  cursor: pointer;

  @media (max-width: 400px) {
    font-size: 10px;
  }
`;

const StyledIcon = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
`;

const StyledCopyright = styled.h6`
  margin: 0px;
  font-family: Roboto-Regular;
  font-size: 15px;
  color: ${constants.colors.white};
  opacity: 0.5;

  @media (max-width: 800px) {
    margin-top: 10px;
  }

  @media (max-width: 700px) {
    margin-top: 20px;
  }

  @media (max-width: 400px) {
    font-size: 12px;
  }
`;

export const Footer = () => {
  return (
    <StyledFooter>
      <StyledAddressAndContacts>
        <ClipboardCopier text="Moldova, Orhei, 14 D. Cantemir street">
          <StyledAddress>
            <StyledIcon>
              <FaGlobe />
            </StyledIcon>
            Moldova, Orhei, 14 D. Cantemir street
          </StyledAddress>
        </ClipboardCopier>
        <StyledContactContainer>
          <ClipboardCopier text="+37379205975">
            <StyledContact>
              <StyledIcon>
                <AiTwotonePhone />
              </StyledIcon>
              +(373)79205975
            </StyledContact>
          </ClipboardCopier>
        </StyledContactContainer>
        <StyledContactContainer>
          <ClipboardCopier text="essencebit@gmail.com">
            <StyledContact>
              <StyledIcon>
                <MdEmail />
              </StyledIcon>
              essencebit@gmail.com
            </StyledContact>
          </ClipboardCopier>
        </StyledContactContainer>
      </StyledAddressAndContacts>
      <StyledCopyright>
        © {new Date().getFullYear()} - Essencebit
      </StyledCopyright>
    </StyledFooter>
  );
};
