import React, { useCallback, useState } from "react";
import styled from "styled-components";

const StyledImage = styled.img`
  image-rendering: high-quality;
  filter: url();
`;

export const Image = ({ src, alt, className }) => {
  const [isShown, setIsShown] = useState(false);

  const showImage = useCallback(() => {
    setIsShown(true);
  }, []);

  return (
    <StyledImage
      src={src}
      alt={alt}
      className={className}
      style={{ visibility: isShown ? "visible" : "hidden" }}
      onLoad={showImage}
    />
  );
};
