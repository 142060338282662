import { useEffect, useState } from "react";
import FontFaceObserver from "fontfaceobserver";
import { constants } from "constants";

const fontsNames = [
  "AustraliaCustom-Italic",
  "Roboto-Black",
  "Roboto-Light",
  "Roboto-Regular",
];

export const useFontsObserver = () => {
  const [observedFontsCount, setObservedFontsCount] = useState(0);

  useEffect(() => {
    fontsNames
      .map((fontName) => new FontFaceObserver(fontName))
      .forEach(async (fontFaceObserver) => {
        try {
          await fontFaceObserver.load(null, constants.fontsLoadTimeout);

          setObservedFontsCount(
            (previousObservedFontsCount) => previousObservedFontsCount + 1
          );
        } catch (error) {
          console.log(error);
        }
      });
  }, []);

  return {
    areAllFontsObserved: observedFontsCount === fontsNames.length,
  };
};
