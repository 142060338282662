import React from "react";
import styled from "styled-components";
import { constants } from "constants";
import { Tip } from "components/tip";
import reactImage from "images/expertise/react.webp";
import reduxImage from "images/expertise/redux.webp";
import jsImage from "images/expertise/js.webp";
import tsImage from "images/expertise/ts.webp";
import htmlImage from "images/expertise/html.webp";
import cssImage from "images/expertise/css.webp";
import netImage from "images/expertise/net.webp";
import cshartImage from "images/expertise/csharp.webp";
import sqlserverImage from "images/expertise/sqlserver.webp";
import mysqlImage from "images/expertise/mysql.webp";
import postgresqlImage from "images/expertise/postgresql.webp";
import rabbitmqImage from "images/expertise/rabbitmq.webp";
import redisImage from "images/expertise/redis.webp";
import dockerImage from "images/expertise/docker.webp";
import nodejsImage from "images/expertise/nodejs.webp";
import styledcomponentsImage from "images/expertise/styledcomponents.webp";
import grpcImage from "images/expertise/grpc.webp";
import solaceImage from "images/expertise/solace.webp";
import elasticsearchImage from "images/expertise/elasticsearch.webp";
import grafanaImage from "images/expertise/grafana.webp";
import sqliteImage from "images/expertise/sqlite.webp";
import graphqlImage from "images/expertise/graphql.webp";
import prometheusImage from "images/expertise/prometheus.webp";
import mongodbImage from "images/expertise/mongodb.webp";
import gitImage from "images/expertise/git.webp";
import azureImage from "images/expertise/azure.webp";
import kubernetesImage from "images/expertise/kubernetes.webp";
import dapperImage from "images/expertise/dapper.webp";
import efImage from "images/expertise/ef.webp";
import signalrImage from "images/expertise/signalr.webp";
import { Image } from "components/image";

const StyledExpertise = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;

  &:hover {
    & > h2 {
      transform: scaleX(1.2);
    }
  }

  @media (max-width: 400px) {
    &:hover {
      & > h2 {
        transform: scaleX(1.1);
      }
    }
  }
`;

const StyledHeader = styled.h2`
  font-family: Roboto-Black;
  font-size: 40px;
  color: ${constants.colors.white};
  text-align: center;
  text-transform: uppercase;
  transition: transform 1s;
  will-change: transform;

  @media (max-width: 800px) {
    font-size: 30px;
  }

  @media (max-width: 500px) {
    font-size: 20px;
  }

  @media (max-width: 400px) {
    font-size: 15px;
  }

  @media (max-width: 350px) {
    font-size: 12px;
  }
`;

const StyledTechnologies = styled.div`
  display: grid;
  column-gap: 100px;
  row-gap: 50px;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;

  @media (max-width: 1200px) {
    column-gap: 50px;
    row-gap: 25px;
  }

  @media (max-width: 900px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  @media (max-width: 500px) {
    column-gap: 20px;
    row-gap: 10px;
  }
`;

const StyledTechonolyImage = styled(Image)`
  width: 60px;
  height: 60px;
  padding: 3px;

  @media (max-width: 800px) {
    width: 40px;
    height: 40px;
  }

  @media (max-width: 400px) {
    width: 30px;
    height: 30px;
  }
`;

const StyledMessage = styled.span`
  font-family: Roboto-Light;
  font-size: 23px;
  color: ${constants.colors.almostWhite};
  text-align: center;
  margin-top: 30px;
  margin-left: 100px;
  margin-right: 100px;

  @media (max-width: 1000px) {
    margin-left: 0px;
    margin-right: 0px;
  }

  @media (max-width: 800px) {
    font-size: 20px;
  }

  @media (max-width: 600px) {
    font-size: 15px;
  }

  @media (max-width: 400px) {
    font-size: 13px;
  }
`;

export const Expertise = () => {
  return (
    <StyledExpertise id="expertise">
      <StyledHeader>We have expertise in</StyledHeader>
      <StyledTechnologies>
        <Tip
          target={
            <StyledTechonolyImage src={reactImage} alt="React / React Native" />
          }
          tip="React / React Native"
        />
        <Tip
          target={<StyledTechonolyImage src={reduxImage} alt="Redux" />}
          tip="Redux"
        />
        <Tip
          target={<StyledTechonolyImage src={jsImage} alt="Javascript" />}
          tip="Javascript"
        />
        <Tip
          target={<StyledTechonolyImage src={tsImage} alt="Typescript" />}
          tip="Typescript"
        />
        <Tip
          target={<StyledTechonolyImage src={htmlImage} alt="HTML" />}
          tip="HTML"
        />
        <Tip
          target={<StyledTechonolyImage src={cssImage} alt="CSS" />}
          tip="CSS"
        />
        <Tip
          target={
            <StyledTechonolyImage
              src={styledcomponentsImage}
              alt="Styled Components"
            />
          }
          tip="Styled Components"
        />
        <Tip
          target={<StyledTechonolyImage src={nodejsImage} alt="Node.JS" />}
          tip="Node.JS"
        />
        <Tip
          target={<StyledTechonolyImage src={netImage} alt=".NET" />}
          tip=".NET"
        />
        <Tip
          target={<StyledTechonolyImage src={cshartImage} alt="C#" />}
          tip="C#"
        />
        <Tip
          target={
            <StyledTechonolyImage src={sqlserverImage} alt="SQL Server" />
          }
          tip="SQL Server"
        />
        <Tip
          target={<StyledTechonolyImage src={mongodbImage} alt="MongoDB" />}
          tip="MongoDB"
        />
        <Tip
          target={
            <StyledTechonolyImage src={postgresqlImage} alt="PostgreSQL" />
          }
          tip="PostgreSQL"
        />
        <Tip
          target={<StyledTechonolyImage src={mysqlImage} alt="MySQL" />}
          tip="MySQL"
        />
        <Tip
          target={<StyledTechonolyImage src={sqliteImage} alt="SQLite" />}
          tip="SQLite"
        />
        <Tip
          target={
            <StyledTechonolyImage
              src={elasticsearchImage}
              alt="Elasticsearch"
            />
          }
          tip="Elasticsearch"
        />
        <Tip
          target={<StyledTechonolyImage src={redisImage} alt="Redis" />}
          tip="Redis"
        />
        <Tip
          target={<StyledTechonolyImage src={rabbitmqImage} alt="RabbitMQ" />}
          tip="RabbitMQ"
        />
        <Tip
          target={<StyledTechonolyImage src={solaceImage} alt="Solace" />}
          tip="Solace"
        />
        <Tip
          target={
            <StyledTechonolyImage src={prometheusImage} alt="Prometheus" />
          }
          tip="Prometheus"
        />
        <Tip
          target={<StyledTechonolyImage src={grafanaImage} alt="Grafana" />}
          tip="Grafana"
        />
        <Tip
          target={<StyledTechonolyImage src={dockerImage} alt="Docker" />}
          tip="Docker"
        />
        <Tip
          target={
            <StyledTechonolyImage src={kubernetesImage} alt="Kubernetes" />
          }
          tip="Kubernetes"
        />
        <Tip
          target={<StyledTechonolyImage src={grpcImage} alt="gRPC" />}
          tip="gRPC"
        />
        <Tip
          target={<StyledTechonolyImage src={signalrImage} alt="SignalR" />}
          tip="SignalR"
        />
        <Tip
          target={<StyledTechonolyImage src={graphqlImage} alt="GraphQL" />}
          tip="GraphQL"
        />
        <Tip
          target={<StyledTechonolyImage src={azureImage} alt="Azure" />}
          tip="Azure"
        />
        <Tip
          target={<StyledTechonolyImage src={efImage} alt="Entity Framework" />}
          tip="Entity Framework"
        />
        <Tip
          target={<StyledTechonolyImage src={dapperImage} alt="Dapper" />}
          tip="Dapper"
        />
        <Tip
          target={<StyledTechonolyImage src={gitImage} alt="Git" />}
          tip="Git"
        />
      </StyledTechnologies>
      <StyledMessage>
        Over the years we gained expertise in multiple fields and techonlogies.
        Knowing a technology in depth means having hands-on experience with it
        for a long time. But we don't forget to keep up with new trends in tech
        world. Adaptiveness is the key, if you want to stay on the market and
        not fall behind. Therefore, we always strive to use in our solutions the
        newest technologies. Conservatism is not about us. We're not affraid to
        try something new.
      </StyledMessage>
    </StyledExpertise>
  );
};
