import { useCallback, useEffect, useMemo, useState } from "react";

export const useWindowVerticalScroll = () => {
  const [verticalScroll, setVerticalScroll] = useState(0);

  const changeVerticalScroll = useCallback(() => {
    setVerticalScroll((previousVerticalScroll) => {
      if (window.scrollY === 0) {
        return 0;
      } else if (previousVerticalScroll === 0) {
        return window.scrollY;
      } else if (Math.abs(previousVerticalScroll - window.scrollY) > 50) {
        return window.scrollY;
      } else {
        return previousVerticalScroll;
      }
    });
  }, []);

  useEffect(() => {
    const scrollEvent = window.addEventListener("scroll", changeVerticalScroll);

    return () => {
      scrollEvent.remove();
    };
  }, []);

  const windowVerticalScroll = useMemo(
    () => ({ verticalScroll }),
    [verticalScroll]
  );

  return windowVerticalScroll;
};
