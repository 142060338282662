import React from "react";
import styled from "styled-components";
import { constants } from "constants";
import craftsmanshipImage from "images/values/craftsmanship.webp";
import attitudeImage from "images/values/attitude.webp";
import { Image } from "components/image";

const StyledOurValues = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;
  margin-left: 100px;
  margin-right: 100px;

  &:hover {
    & > h2 {
      transform: scaleX(1.2);
    }
  }

  @media (max-width: 1000px) {
    margin-left: 0px;
    margin-right: 0px;
  }

  @media (max-width: 400px) {
    &:hover {
      & > h2 {
        transform: scaleX(1.1);
      }
    }
  }
`;

const StyledHeader = styled.h2`
  font-family: Roboto-Black;
  font-size: 40px;
  color: ${constants.colors.white};
  text-align: center;
  text-transform: uppercase;
  transition: transform 1s;
  will-change: transform;

  @media (max-width: 800px) {
    font-size: 30px;
  }

  @media (max-width: 500px) {
    font-size: 20px;
  }

  @media (max-width: 400px) {
    font-size: 15px;
  }

  @media (max-width: 350px) {
    font-size: 12px;
  }
`;

const StyledBody = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 50px;

  @media (max-width: 850px) {
    grid-template-columns: 1fr;
  }
`;

const StyledValue = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledValueImage = styled(Image)`
  width: 100px;
  height: 100px;

  @media (max-width: 800px) {
    width: 80px;
    height: 80px;
  }

  @media (max-width: 400px) {
    width: 50px;
    height: 50px;
  }
`;

const StyledMessage = styled.span`
  font-family: Roboto-Black;
  font-size: 25px;
  color: ${constants.colors.almostWhite};
  text-transform: uppercase;
  margin-top: 20px;
  text-align: center;

  @media (max-width: 800px) {
    font-size: 20px;
  }

  @media (max-width: 500px) {
    font-size: 15px;
  }

  @media (max-width: 400px) {
    font-size: 13px;
  }
`;

const StyledWeDo = styled.span`
  font-family: AustraliaCustom-Italic;
  font-size: 22px;
  text-transform: none;

  @media (max-width: 800px) {
    font-size: 18px;
  }

  @media (max-width: 500px) {
    font-size: 13px;
  }

  @media (max-width: 400px) {
    font-size: 11px;
  }
`;

const StyledDescription = styled.span`
  font-family: Roboto-Light;
  font-size: 17px;
  color: ${constants.colors.almostWhite};
  margin-top: 20px;
  text-align: center;

  @media (max-width: 800px) {
    font-size: 15px;
  }

  @media (max-width: 500px) {
    font-size: 12px;
  }

  @media (max-width: 400px) {
    font-size: 11px;
  }
`;

export const OurValues = () => {
  return (
    <StyledOurValues id="our-values">
      <StyledHeader>Our values</StyledHeader>
      <StyledBody>
        <StyledValue>
          <StyledValueImage src={craftsmanshipImage} alt="Craftsmanship" />
          <StyledMessage>
            Craftsmanship -{" "}
            <StyledWeDo style={{ color: constants.colors.bit }}>
              We can
            </StyledWeDo>
          </StyledMessage>
          <StyledDescription>
            Regardless of how much volition you have, you can't build a piece of
            software without knowing how to do that. Having technical expertise
            is essential. Without in-depth knowledge of computer science and
            afferent technologies you will not be able to make "miracles" in
            software development.
          </StyledDescription>
        </StyledValue>
        <StyledValue>
          <StyledValueImage src={attitudeImage} alt="Attitude" />
          <StyledMessage>
            Attitude -{" "}
            <StyledWeDo style={{ color: constants.colors.essence }}>
              We care
            </StyledWeDo>
          </StyledMessage>
          <StyledDescription>
            The attitude at work is important. It's important to be diligent, to
            pay attention to details, "to wear customer lens", to know the
            reasons, to know the goals, to enjoy the process, to feel the
            impact. It will light your path to success. We do that.
          </StyledDescription>
        </StyledValue>
      </StyledBody>
    </StyledOurValues>
  );
};
