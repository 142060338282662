import React from "react";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import { constants } from "constants";

const mapContainer = { width: "100%", height: "100%" };
const center = { lat: 47.0104529, lng: 28.8638103 };

const options = {
  keyboardShortcuts: false,
  fullscreenControl: true,
};

export const Map = () => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: constants.googleMapsApiKey,
  });

  return (
    <>
      {isLoaded ? (
        <GoogleMap
          mapContainerStyle={mapContainer}
          center={center}
          zoom={8}
          options={options}
        >
          <Marker position={center} />
        </GoogleMap>
      ) : (
        <></>
      )}
    </>
  );
};
