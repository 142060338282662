import { useCallback, useContext, useEffect } from "react";
import backgroundRunnersParameters from "components/backgroundRunners/backgroundRunnersParameters.json";
import { isCursorCollisioned } from "components/backgroundRunners/cursorCollision";
import { useCursor } from "components/backgroundRunners/hooks/useCursor";
import { normalizeAngle } from "components/backgroundRunners/angleNormalization";
import { getRicochetAngle } from "components/backgroundRunners/angleRicochet";
import { MarginHitContext } from "contexts/marginHitContext";

export const useRunnersAnimator = (
  runners,
  maximumX,
  maximumY,
  backgroundYOffset,
  updater
) => {
  const { hitMargin } = useContext(MarginHitContext);
  const { cursorX, cursorY } = useCursor();

  const updateRunner = useCallback(
    (runner) => {
      runners[runner.index] = runner;
    },
    [runners]
  );

  const checkCursorCollision = useCallback(
    (runner) => {
      const { x, y } = runner;
      const cursorYWithOffset = cursorY - backgroundYOffset + window.scrollY;

      if (
        isCursorCollisioned(
          runner,
          { cursorX, cursorYWithOffset },
          backgroundYOffset
        )
      ) {
        const runnerCenterX = x + backgroundRunnersParameters.runnerRadius;
        const runnerCenterY = y + backgroundRunnersParameters.runnerRadius;

        const hitAngle = normalizeAngle(
          Math.atan2(
            -(cursorYWithOffset - runnerCenterY),
            cursorX - runnerCenterX
          )
        );

        const moveAngle = normalizeAngle(hitAngle + Math.PI);

        updateRunner({
          ...runner,
          angle: moveAngle,
          speed: backgroundRunnersParameters.hitSpeed,
        });
      }
    },
    [updater, cursorX, cursorY]
  );

  const checkMarginCollision = useCallback(
    (runner) => {
      const { x, y, angle } = runner;
      const isTopBackgroundMarginHit = y <= 0;
      const isBottomBackgroundMarginHit = y >= maximumY;
      const isLeftBackgroundMarginHit = x <= 0;
      const isRightBackgroundMarginHit = x >= maximumX;

      if (isTopBackgroundMarginHit) {
        hitMargin("top");
      }

      if (isBottomBackgroundMarginHit) {
        hitMargin("bottom");
      }

      if (isLeftBackgroundMarginHit) {
        hitMargin("left");
      }

      if (isRightBackgroundMarginHit) {
        hitMargin("right");
      }

      const isAnyBackgroundMarginHit =
        isTopBackgroundMarginHit ||
        isBottomBackgroundMarginHit ||
        isLeftBackgroundMarginHit ||
        isRightBackgroundMarginHit;

      if (isAnyBackgroundMarginHit) {
        const { ricochetX, ricochetY, ricochetAngle } = getRicochetAngle(
          x,
          y,
          maximumX,
          maximumY,
          angle
        );

        updateRunner({
          ...runner,
          x: ricochetX,
          y: ricochetY,
          angle: ricochetAngle,
        });
      }
    },
    [updater, maximumX, maximumY]
  );

  const move = useCallback(
    (runner) => {
      const { x, y, angle, speed } = runner;
      const deltaX = Math.cos(angle) * speed;
      const deltaY = -Math.sin(angle) * speed;

      const nextSpeed =
        speed / backgroundRunnersParameters.speedDevider >
        backgroundRunnersParameters.minSpeed
          ? speed / backgroundRunnersParameters.speedDevider
          : speed;

      updateRunner({
        ...runner,
        x: x + deltaX,
        y: y + deltaY,
        speed: nextSpeed,
      });
    },
    [updater]
  );

  useEffect(() => {
    for (let runnerIndex = 0; runnerIndex < runners.length; runnerIndex++) {
      checkCursorCollision(runners[runnerIndex]);
      checkMarginCollision(runners[runnerIndex]);
      move(runners[runnerIndex]);
    }
  }, [updater]);
};
