import { useMemo, useRef } from "react";

export const useTooltipPortal = () => {
  const tooltipPortalRef = useRef(null);

  const tooltipPortal = useMemo(
    () => ({ tooltipPortalRef }),
    [tooltipPortalRef]
  );

  return tooltipPortal;
};
