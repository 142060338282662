import backgroundRunnersParameters from "components/backgroundRunners/backgroundRunnersParameters.json";
import { normalizeAngle } from "components/backgroundRunners/angleNormalization";

export const getRicochetAngle = (x, y, maximumX, maximumY, angle) => {
  let ricochetX = x;
  let ricochetY = y;
  let ricochetAngle = angle;

  if (x <= 0) {
    ricochetAngle = getBorderlineRicochetAngle(angle, Math.PI / 2);
    ricochetX = backgroundRunnersParameters.almostZero;
  }

  if (x >= maximumX) {
    ricochetAngle = getBorderlineRicochetAngle(angle, Math.PI / 2);
    ricochetX = maximumX - backgroundRunnersParameters.almostZero;
  }

  if (y <= 0) {
    ricochetAngle = getBorderlineRicochetAngle(angle, 0);
    ricochetY = backgroundRunnersParameters.almostZero;
  }

  if (y >= maximumY) {
    ricochetAngle = getBorderlineRicochetAngle(angle, 0);
    ricochetY = maximumY - backgroundRunnersParameters.almostZero;
  }

  return {
    ricochetX,
    ricochetY,
    ricochetAngle,
  };
};

export const getBorderlineRicochetAngle = (angle, borderlineAngle) => {
  return normalizeAngle(angle - (angle - borderlineAngle) * 2);
};
