import React, { useRef } from "react";
import styled, { keyframes } from "styled-components";
import { useVisibilityObserver } from "hooks/useVisibilityObserver";
import { isSafari } from "utilities/browsers";

const opacityAndShadowAnimation = keyframes`
  from {
    opacity: 0;
    text-shadow: 0 0 5px rgb(255,255,255,0.5);
  }
  75% {
    opacity: 0.75;
    text-shadow: 0 0 5px rgb(255,255,255,0.5);
  }
  to {
    opacity: 1;
    text-shadow: none;
  }
`;

const shadowAnimation = keyframes`
  from {
    text-shadow: 0 0 5px rgb(255,255,255,0.5);
  }
  75% {
    text-shadow: 0 0 5px rgb(255,255,255,0.5);
  }
  to {
    text-shadow: none;
  }
`;

const StyledVisibilityAnimator = styled.div`
  position: ${({ $nonRelative }) => ($nonRelative ? undefined : "relative")};
  z-index: ${({ $zIndex }) => $zIndex};
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity ${isSafari ? 1 : 2}s, text-shadow ${isSafari ? 1 : 2}s;
  will-change: opacity, text-shadow;

  &.opacity {
    opacity: 1;
    animation: ${opacityAndShadowAnimation} ${isSafari ? 1 : 2}s;
  }

  @media (max-width: 600px) {
    opacity: 1;
    transition: text-shadow 2s;
    will-change: text-shadow;

    &.opacity {
      animation: ${shadowAnimation} 2s;
    }
  }
`;

export const VisibilityAnimator = ({
  children,
  animationType,
  showInstantly,
  zIndex,
  nonRelative,
}) => {
  const animatorRef = useRef(null);
  const { isVisible } = useVisibilityObserver(animatorRef);

  return (
    <StyledVisibilityAnimator
      ref={animatorRef}
      className={showInstantly || isVisible ? animationType : undefined}
      $zIndex={zIndex}
      $nonRelative={nonRelative}
    >
      {children}
    </StyledVisibilityAnimator>
  );
};
