import React from "react";
import styled from "styled-components";
import { FaMobileAlt, FaServer, FaWrench } from "react-icons/fa";
import { MdMonitor } from "react-icons/md";
import { BsArrowLeftRight, BsSpeedometer } from "react-icons/bs";
import { constants } from "constants";

const serverIconSize = 30;
const arrowIconSize = 15;
const mobileIconSize = 30;
const monitorIconSize = 30;
const maintenanceIconSize = 30;
const performanceIconSize = 30;

const StyledOurServices = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;
  margin-left: 100px;
  margin-right: 100px;
  padding-left: 100px;
  padding-right: 100px;

  &:hover {
    & > h2 {
      transform: scaleX(1.2);
    }
  }

  @media (max-width: 1500px) {
    padding-left: 0px;
    padding-right: 0px;
  }

  @media (max-width: 1000px) {
    margin-left: 0px;
    margin-right: 0px;
  }

  @media (max-width: 400px) {
    &:hover {
      & > h2 {
        transform: scaleX(1.1);
      }
    }
  }
`;

const StyledHeader = styled.h2`
  font-family: Roboto-Black;
  font-size: 40px;
  color: ${constants.colors.white};
  text-align: center;
  text-transform: uppercase;
  transition: transform 1s;
  will-change: transform;

  @media (max-width: 800px) {
    font-size: 30px;
  }

  @media (max-width: 500px) {
    font-size: 20px;
  }

  @media (max-width: 400px) {
    font-size: 15px;
  }

  @media (max-width: 350px) {
    font-size: 12px;
  }
`;

const StyledServiceContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${({ orientation }) => `flex-${orientation}`};
  align-items: flex-start;

  &:not(:first-of-type) {
    margin-top: 50px;
  }

  @media (max-width: 800px) {
    flex-direction: ${({ orientation }) =>
      orientation === "start" ? "column" : "column-reverse"};
    align-items: center;
  }
`;

const StyledService = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledServiceIcons = styled.div`
  display: grid;
  grid-template-columns: ${({ $iconsCount }) =>
    new Array($iconsCount).fill("1fr").join(" ")};
  grid-gap: 10px;
  justify-items: center;
  align-items: center;
  color: ${constants.colors.white};
`;

const StyledMessage = styled.span`
  font-family: Roboto-Black;
  font-size: 20px;
  color: ${constants.colors.almostWhite};
  margin-top: 10px;
  text-align: center;

  @media (max-width: 800px) {
    font-size: 20px;
  }

  @media (max-width: 500px) {
    font-size: 15px;
  }

  @media (max-width: 400px) {
    font-size: 13px;
  }
`;

const StyledServiceDescription = styled.span`
  flex: 1;
  font-family: Roboto-Light;
  font-size: 17px;
  color: ${constants.colors.almostWhite};
  text-align: left;
  margin-${({ orientation }) => orientation}: 30px;

  @media (max-width: 800px) {
    font-size: 15px;
    text-align: center;
    margin: 20px 0px 0px 0px;
  }

  @media (max-width: 500px) {
    font-size: 12px;
  }

  @media (max-width: 400px) {
    font-size: 11px;
  }
`;

export const OurServices = () => {
  return (
    <StyledOurServices id="our-services">
      <StyledHeader>Our services</StyledHeader>
      <StyledServiceContainer orientation="start">
        <StyledService>
          <StyledServiceIcons $iconsCount={3}>
            <FaMobileAlt size={mobileIconSize} />
            <BsArrowLeftRight size={arrowIconSize} />
            <FaServer size={serverIconSize} />
          </StyledServiceIcons>
          <StyledMessage>Mobile Development</StyledMessage>
        </StyledService>
        <StyledServiceDescription orientation="left">
          Let's be honest. Almost every business needs a mobile app nowadays. If
          you don't have it, you loose a big number of customers. We have good
          expertise in mobile development and can help you build a cool mobile
          solution, so that your dear customers stay closer to you.
        </StyledServiceDescription>
      </StyledServiceContainer>
      <StyledServiceContainer orientation="end">
        <StyledServiceDescription orientation="right">
          Internet descended on us a long time ago. We're more connected than
          ever. The need in desktop apps decreased significantly over the years.
          Everything's on the internet now. A web solution is a must. We can
          help you build one, ooooor if you have a desktop app, we can migrate
          it to web. We have vast experience in this and it is guaranteed that
          you'll get a well designed, scalable, highly available and a lightning
          fast web application, built following the best practices and standards
          in the industry.
        </StyledServiceDescription>
        <StyledService>
          <StyledServiceIcons $iconsCount={3}>
            <MdMonitor size={monitorIconSize} />
            <BsArrowLeftRight size={arrowIconSize} />
            <FaServer size={serverIconSize} />
          </StyledServiceIcons>
          <StyledMessage>Web Development</StyledMessage>
        </StyledService>
      </StyledServiceContainer>
      <StyledServiceContainer orientation="start">
        <StyledService>
          <StyledServiceIcons $iconsCount={1}>
            <FaWrench size={maintenanceIconSize} />
          </StyledServiceIcons>
          <StyledMessage>Maintenance</StyledMessage>
        </StyledService>
        <StyledServiceDescription orientation="left">
          Every application needs maintenance. From time to time you need to
          make changes in your app to respond to needs of the market, to
          troubleshoot or to be in step with the latest technologies. We'll take
          care of your app after it goes to production, wheather this app is
          built by us or somebody else. We'll provide full technical support, so
          that you can focus only on your business.
        </StyledServiceDescription>
      </StyledServiceContainer>
      <StyledServiceContainer orientation="end">
        <StyledServiceDescription orientation="right">
          User experience is essential and time is precious. Nobody likes to
          wait. User-app interaction must be instantaneous, otherwise you loose
          the clients. You need to stay ahead of your competitors. We can tweak
          your app and push it to the limits of performance. We already did that
          for other clients and they saved lots of money out of this. We reduced
          the maintenance costs, improved user experience and increased customer
          satisfaction as effect.
        </StyledServiceDescription>
        <StyledService>
          <StyledServiceIcons $iconsCount={1}>
            <BsSpeedometer size={performanceIconSize} />
          </StyledServiceIcons>
          <StyledMessage>Performance Improvement</StyledMessage>
        </StyledService>
      </StyledServiceContainer>
    </StyledOurServices>
  );
};
