import React from "react";
import { useTooltipPortal } from "hooks/useTooltipPortal";
import { TooltipPortalContext } from "contexts/tooltipPortalContext";

export const TooltipPortalWrapper = ({ children }) => {
  const tooltipPortal = useTooltipPortal();

  return (
    <TooltipPortalContext.Provider value={tooltipPortal}>
      {children}
    </TooltipPortalContext.Provider>
  );
};
