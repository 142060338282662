import { useCallback, useEffect, useRef } from "react";
import { constants } from "constants";
import backgroundRunnersParameters from "components/backgroundRunners/backgroundRunnersParameters.json";
import { normalizeAngle } from "components/backgroundRunners/angleNormalization";

export const useRunnersInitializer = (maximumX, maximumY) => {
  const runnersRef = useRef([]);

  const initializeRunners = useCallback(() => {
    const runners = new Array(backgroundRunnersParameters.runnersCount)
      .fill(undefined)
      .map((_, index) => ({
        index,
        color:
          index === backgroundRunnersParameters.runnersCount - 1
            ? constants.colors.essenceTransparent
            : constants.colors.bitTransparent,
        x: maximumX * Math.random(),
        y: maximumY * Math.random(),
        angle: normalizeAngle(2 * Math.PI * Math.random()),
        speed: backgroundRunnersParameters.minSpeed,
      }));

    runnersRef.current = runners;
  }, [maximumX, maximumY]);

  useEffect(() => {
    const isBackgroundInitialized =
      maximumX !== undefined && maximumY !== undefined;

    if (isBackgroundInitialized) {
      initializeRunners();
    }
  }, [maximumX, maximumY, initializeRunners]);

  return {
    runners: runnersRef.current,
  };
};
