import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { constants } from "constants";

const message = "essence";

const StyledMessage = styled.span`
  font-size: inherit;
`;

const StyledLetter = styled.span`
  color: ${({ color }) => color};
  text-shadow: ${({ $withShadow }) =>
    $withShadow ? "0 0 2px rgb(255,255,255,0.2)" : "none"};
  transition: color 0.5s, text-shadow 0.5s;
  will-change: color, text-shadow;
`;

export const Essence = () => {
  const [bitIndex, setBitIndex] = useState(0);

  useEffect(() => {
    setInterval(() => {
      setBitIndex(
        (previousEssenceIndex) => (previousEssenceIndex + 1) % message.length
      );
    }, 1000);
  }, []);

  return (
    <StyledMessage>
      {message.split("").map((essence, essenceIndex) => (
        <StyledLetter
          key={essenceIndex}
          color={
            essenceIndex === bitIndex
              ? constants.colors.bit
              : constants.colors.essence
          }
          $withShadow={essenceIndex === bitIndex}
        >
          {essence}
        </StyledLetter>
      ))}
    </StyledMessage>
  );
};
