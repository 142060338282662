import React from "react";
import { useMarginHit } from "hooks/useMarginHit";
import { MarginHitContext } from "contexts/marginHitContext";

export const MarginHitWrapper = ({ children }) => {
  const marginHit = useMarginHit();

  return (
    <MarginHitContext.Provider value={marginHit}>
      {children}
    </MarginHitContext.Provider>
  );
};
