import React from "react";

const privacyPolicyUpdatedAt = "23/07/2024";

export const ReminderBroPrivacyPolicy = () => {
  return (
    <div>
      <h3>Privacy Policy for Reminder Bro mobile application</h3>
      <br />
      Last updated: <b>{privacyPolicyUpdatedAt}</b>
      <br />
      <br />
      <p>
        <b>Essencebit SRL</b> (<b>“us”</b>, <b>“we”</b>, or <b>“our”</b>)
        operates the <b>Reminder Bro</b> mobile application (<b>“App”</b>).
        <br />
        This page informs you of our policies regarding the collection, use and
        share of personal data when you use our App and the choices you have
        associated with that data.
        <br />
        <br /> Information Collection, Use and Share:
        <br />
        <b>- Data Collection:</b> We do not collect any data from the users of
        our App.
        <br />
        <b>- Data Use:</b> We do not use any data from the users of our App.
        <br />
        <b>- Data Share:</b> We do not share any data from the users of our App
        to any 3rd party.
        <br />
        <br />
        <b>- Contact Us:</b> If you have any questions about this{" "}
        <b>Privacy Policy</b>, please contact us at{" "}
        <a href="/">{window.location.origin}</a> by using the chatbot or by
        using the means from <b>"Contact Us"</b> section.
        <br />
        <br />
        <b>- Privacy Policy Changes:</b> We may update our <b>Privacy Policy</b>{" "}
        from time to time. You are advised to review this <b>Privacy Policy</b>{" "}
        periodically for any changes. This <b>Privacy Policy</b> is effective as
        of <b>{privacyPolicyUpdatedAt}</b> and will remain in effect except with
        respect to any changes in its provisions in the future, which will be in
        effect immediately after being posted on this page.
      </p>
    </div>
  );
};
