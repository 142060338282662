import React, { useCallback } from "react";
import styled, { keyframes } from "styled-components";
import { BsChevronDoubleUp } from "react-icons/bs";
import { animateScroll } from "react-scroll";
import { constants } from "constants";
import { useWindowVerticalScroll } from "hooks/useWindowVerticalScroll";

const positionAnimation = keyframes`
  from {
    transform: translateY(0px);
  }
  25% {
    transform: translateY(-10px);
  }
  75% {
    transform: translateY(10px);
  }
  to {
    transform: translateY(0px);
  }
`;

const appearanceAnimation = keyframes`
  from {
    transform: scale(0);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
`;

const disappearanceAnimation = keyframes`
  from {
    transform: scale(1);
    opacity: 1;
  }
  to {
    transform: scale(0);
    opacity: 0;
  }
`;

const StyledTopScroller = styled.div`
  position: fixed;
  bottom: 150px;
  right: 24px;
  width: 60px;
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 1000px) {
    right: 30px;
  }

  @media (max-width: 700px) {
    bottom: 100px;
  }

  @media (max-width: 400px) {
    right: 0px;
  }
`;

const StyledScroller = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: ${constants.colors.white};
  cursor: pointer;

  transition: opacity 1s, transform 1s;
  will-change: opacity, transform;

  &.visible {
    animation: ${appearanceAnimation} 1s forwards;
  }

  &.hidden {
    animation: ${disappearanceAnimation} 1s forwards;
  }
`;

const StyledScrollerIcon = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 60px;
  z-index: 1;
  transition: opacity 1s, transform 3s;
  will-change: opacity, transform;
  animation: ${positionAnimation} 3s linear infinite;

  &:hover {
    opacity: 0.5;
  }

  @media (max-width: 600px) {
    font-size: 40px;
    background-color: ${constants.colors.grey}ee;
    padding: 5px;
    border-radius: 40px;
  }

  @media (max-width: 400px) {
    font-size: 30px;
    background-color: ${constants.colors.grey}ee;
    padding: 3px;
    border-radius: 30px;
  }
`;

export const TopScroller = () => {
  const { verticalScroll } = useWindowVerticalScroll();

  const scrollToTop = useCallback(() => {
    animateScroll.scrollTo(-1, { smooth: true, duration: 0, delay: 0 });
  }, []);

  return (
    <StyledTopScroller>
      <StyledScroller
        className={
          verticalScroll < window.innerHeight / 2 ? "hidden" : "visible"
        }
        onClick={scrollToTop}
      >
        <StyledScrollerIcon>
          <BsChevronDoubleUp />
        </StyledScrollerIcon>
      </StyledScroller>
    </StyledTopScroller>
  );
};
