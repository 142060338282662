import { useCallback, useMemo, useRef, useState } from "react";

export const useMarginHit = () => {
  const [isTopMarginHit, setIsTopMarginHit] = useState(false);
  const [isBottomMarginHit, setIsBottomMarginHit] = useState(false);
  const [isLeftMarginHit, setIsLeftMarginHit] = useState(false);
  const [isRightMarginHit, setIsRightMarginHit] = useState(false);
  const topTimeoutIdRef = useRef(null);
  const bottomTimeoutIdRef = useRef(null);
  const leftTimeoutIdRef = useRef(null);
  const rightTimeoutIdRef = useRef(null);

  const hitMargin = useCallback((marginPosition) => {
    switch (marginPosition) {
      case "top":
        clearTimeout(topTimeoutIdRef.current);
        setIsTopMarginHit(true);

        topTimeoutIdRef.current = setTimeout(() => {
          setIsTopMarginHit(false);
        }, 500);

        break;
      case "bottom":
        clearTimeout(bottomTimeoutIdRef.current);
        setIsBottomMarginHit(true);

        bottomTimeoutIdRef.current = setTimeout(() => {
          setIsBottomMarginHit(false);
        }, 500);

        break;
      case "left":
        clearTimeout(leftTimeoutIdRef.current);
        setIsLeftMarginHit(true);

        leftTimeoutIdRef.current = setTimeout(() => {
          setIsLeftMarginHit(false);
        }, 500);

        break;
      case "right":
        clearTimeout(rightTimeoutIdRef.current);
        setIsRightMarginHit(true);

        rightTimeoutIdRef.current = setTimeout(() => {
          setIsRightMarginHit(false);
        }, 500);

        break;
    }
  }, []);

  const topMarginHit = useMemo(
    () => ({
      isTopMarginHit,
      isBottomMarginHit,
      isLeftMarginHit,
      isRightMarginHit,
      hitMargin,
    }),
    [isTopMarginHit, isBottomMarginHit, isLeftMarginHit, isRightMarginHit]
  );

  return topMarginHit;
};
