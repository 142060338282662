import React from "react";
import styled from "styled-components";
import { constants } from "constants";
import linkedinImage from "images/contacts/linkedin.webp";
import facebookImage from "images/contacts/facebook.webp";
import instagramImage from "images/contacts/instagram.webp";
import gmailImage from "images/contacts/gmail.webp";
import { Image } from "components/image";

const StyledContacts = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 100px;

  &:hover {
    & > h2 {
      transform: scaleX(1.2);
    }
  }

  @media (max-width: 400px) {
    &:hover {
      & > h2 {
        transform: scaleX(1.1);
      }
    }
  }
`;

const StyledHeader = styled.h2`
  font-family: Roboto-Black;
  font-size: 40px;
  color: ${constants.colors.white};
  text-align: center;
  text-transform: uppercase;
  transition: transform 1s;
  will-change: transform;

  @media (max-width: 800px) {
    font-size: 30px;
  }

  @media (max-width: 500px) {
    font-size: 20px;
  }

  @media (max-width: 400px) {
    font-size: 15px;
  }

  @media (max-width: 350px) {
    font-size: 12px;
  }
`;

const StyledContactsBody = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: 60px;

  @media (max-width: 800px) {
    column-gap: 30px;
  }
`;

const StyledContact = styled.a`
  cursor: pointer;
  transition: opacity 1s;
  will-change: opacity;

  &:hover {
    opacity: 0.5;
  }
`;

const StyledContactImage = styled(Image)`
  width: 60px;
  height: 60px;

  @media (max-width: 800px) {
    width: 40px;
    height: 40px;
  }

  @media (max-width: 400px) {
    width: 30px;
    height: 30px;
  }
`;

export const Contacts = () => {
  return (
    <StyledContacts id="contacts">
      <StyledHeader>Contact us on</StyledHeader>
      <StyledContactsBody>
        <StyledContact
          href="https://www.linkedin.com/company/essencebit"
          target="_blank"
        >
          <StyledContactImage src={linkedinImage} alt="LinkedIn" />
        </StyledContact>
        <StyledContact
          href="https://www.facebook.com/Essencebit"
          target="_blank"
        >
          <StyledContactImage src={facebookImage} alt="Facebook" />
        </StyledContact>
        <StyledContact
          href="https://www.instagram.com/essencebit_official"
          target="_blank"
        >
          <StyledContactImage src={instagramImage} alt="Instagram" />
        </StyledContact>
        <StyledContact href="mailto:essencebit@gmail.com">
          <StyledContactImage src={gmailImage} alt="Gmail" />
        </StyledContact>
      </StyledContactsBody>
    </StyledContacts>
  );
};
