import { App } from "app";
import { ReminderBroPrivacyPolicy } from "privacyPolicies/reminderBro";

export const Router = () => {
  const currentRoute = window.location.pathname;

  if (currentRoute === "/privacy-policy/reminder-bro") {
    return <ReminderBroPrivacyPolicy />;
  }

  return <App />;
};
