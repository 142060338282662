import React from "react";
import styled from "styled-components";
import { constants } from "constants";
import { useWindowVerticalScroll } from "hooks/useWindowVerticalScroll";
import { Bits } from "components/bits";
import { Essence } from "components/essence";
import { Tip } from "components/tip";

const StyledMottoContainer = styled.div`
  position: absolute;
  top: 0px;
  left: 30px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  @media (max-width: 800px) {
    right: 30px;
    justify-content: center;
  }
`;

const StyledMotto = styled.h1`
  font-family: AustraliaCustom-Italic;
  font-size: 20px;
  letter-spacing: 2px;
  color: ${constants.colors.white};
  text-align: center;
  margin: 0px;
  opacity: ${({ $visible }) => ($visible ? 1 : 0)};
  cursor: ${({ $visible }) => ($visible ? "text" : "default")};
  transition: opacity 1s;
  will-change: opacity;

  @media (max-width: 1000px) {
    font-size: 25px;
  }

  @media (max-width: 800px) {
    font-size: 30px;
  }

  @media (max-width: 500px) {
    font-size: 25px;
  }

  @media (max-width: 400px) {
    font-size: 20px;
  }

  @media (max-width: 350px) {
    font-size: 15px;
  }
`;

export const Motto = () => {
  const { verticalScroll } = useWindowVerticalScroll();

  return (
    <StyledMottoContainer>
      <Tip
        target={
          <StyledMotto $visible={verticalScroll === 0}>
            Bringing <Essence /> through <Bits />
          </StyledMotto>
        }
        tip="Bringing essence through bits"
        disabled={verticalScroll > 0}
        portalized
      />
    </StyledMottoContainer>
  );
};
