import React, { useCallback, useRef, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import styled, { keyframes } from "styled-components";
import { constants } from "constants";

const appearanceAnimation = keyframes`
  from {
    opacity: 0;
    padding-left: 0px;
    padding-right: 0px;
    margin-left: 0px;
  }
  1% {
    opacity: 0;
    padding-left: 3px;
    padding-right: 3px;
    margin-left: 3px;
  }
  to {
    opacity: 1;
    padding-left: 3px;
    padding-right: 3px;
    margin-left: 3px;
  }
`;

const disappearanceAnimation = keyframes`
  from {
    opacity: 1;
    padding-left: 3px;
    padding-right: 3px;
    margin-left: 3px;
  }
  99% {
    opacity: 0;
    padding-left: 3px;
    padding-right: 3px;
    margin-left: 3px;
  }
  to {
    opacity: 0;
    padding-left: 0px;
    padding-right: 0px;
    margin-left: 0px;
  }
`;

const fontSizeIncreaseAnimation = keyframes`
  from {
    font-size: 0px;
  }
  1% {
    font-size: 12px;
  }
  to {
    font-size: 12px;
  }
`;

const fontSizeDecreaseAnimation = keyframes`
  from {
    font-size: 12px;
  }
  99% {
    font-size: 12px;
  }
  to {
    font-size: 0px;
  }
`;

const StyledClipboardCopier = styled.div`
  position: relative;
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const StyledCopied = styled.div`
  position: absolute;
  top: 0px;
  left: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: ${constants.colors.white};
  opacity: 0;
  padding: 0px;
  margin-left: 0px;
  border-radius: 3px;
  z-index: 0;
  transition: opacity 1s, padding-left 1s, padding-right 1s, margin-left 1s;
  will-change: opacity, padding-left, padding-right, margin-left;

  &.visible {
    animation: ${appearanceAnimation} 1s forwards;
  }

  &.hidden {
    animation: ${disappearanceAnimation} 1s forwards;
  }

  & > span {
    font-size: 0px;
    transition: font-size 1.1s;
    will-change: font-size;
  }

  &.visible > span {
    animation: ${fontSizeIncreaseAnimation} 1.1s forwards;
  }

  &.hidden > span {
    animation: ${fontSizeDecreaseAnimation} 1.1s forwards;
  }
`;

const StyledCopiedText = styled.span`
  font-family: Roboto-Regular;
  color: ${constants.colors.black};
`;

export const ClipboardCopier = ({ children, text }) => {
  const [isTextCopied, setIsTextCopied] = useState(null);
  const timeoutIdRef = useRef(null);

  const showTextCopied = useCallback(() => {
    clearTimeout(timeoutIdRef.current);
    setIsTextCopied(true);

    timeoutIdRef.current = setTimeout(() => {
      setIsTextCopied(false);
    }, 1500);
  }, []);

  return (
    <CopyToClipboard text={text} onCopy={showTextCopied}>
      <StyledClipboardCopier>
        {children}
        <StyledCopied
          className={
            isTextCopied === null
              ? undefined
              : isTextCopied
              ? "visible"
              : "hidden"
          }
        >
          <StyledCopiedText>copied</StyledCopiedText>
        </StyledCopied>
      </StyledClipboardCopier>
    </CopyToClipboard>
  );
};
